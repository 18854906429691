<template>
    <div v-if="!ready" class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-else class="container">
        <form>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">名 称<i class="rqd">*</i></label>
                <input type="text" class="form-control w-100 rounded" name="name" placeholder="请输入企业名称" required v-model.trim.lazy="data.name" />
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >企 业<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.company_id">
                    <option v-for="companyList in companyData" :key="companyList.id" :value="companyList.id"> {{ companyList.name }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >类 型<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.type_id">
                    <option v-for="(jobsType,index) in JOBS_TYPE" :key="index" :value="index"> {{ jobsType }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >学历要求<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.schooling">
                    <option v-for="(jobsSchooling,index) in JOBS_SCHOOLING" :key="index" :value="index"> {{ jobsSchooling }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >年龄要求<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.ages">
                    <option v-for="(ages,index) in AGEINDEX" :key="index" :value="index"> {{ ages }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >综合工资<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.salarys">
                    <option v-for="(salarys,index) in SALARYINDEX" :key="index" :value="index"> {{ salarys }} </option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >福利待遇<i class="rqd"></i></span>
                <div class="w-100">
                    <select class="form-select p-2 w-100 rounded" multiple="multiple" v-model="data.tags">
                        <option v-for="(name, code) in ADVANTAGE" :key="code" :value="code">{{ name }}</option>
                    </select>
                </div>
            </div>
            <div class="input-group my-1 has-feedback">
                <span class="m-1" >首页显示<i class="rqd"></i></span>
                <select class="form-control w-100 rounded form-select" v-model="data.homeshow">
                    <option value="0">小时工</option>
                    <option value="1">返 费</option>
                </select>
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">首页显示单价</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入单价，不做计薪" v-model.trim.lazy="data.home_price " />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">招聘人数</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入招聘人数" v-model.trim.lazy="data.people_number " />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">面试时间</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入面试时间" v-model.trim.lazy="data.interview_time " />
            </div>
            
            <div id="address" class="input-group my-1 has-feedback">
                <label class="m-1">地 址</label>
                <myAddress :addr="address" @changed="saveAddr"/>
            </div>
            
            <div class="input-group my-1 has-feedback">
                <label class="m-1">联系人</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系人姓名" v-model.trim.lazy="data.contact" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">联系电话</label>
                <input type="text" class="form-control w-100 rounded" placeholder="请输入联系电话" v-model.trim.lazy="data.contact_tel" />
            </div>
            <div class="input-group my-1 has-feedback">
                <label class="m-1">其它说明</label>
                <textarea class="form-control w-100 p-2" rows="6" v-model.trim.lazy="data.content"></textarea>
            </div>
            <div class="d-flex justify-content-evenly">
                <button class="btn btn-secondary btn-lg mt-5 mx-2" @click.prevent="cancel">取 消</button>
                <button class="btn btn-success btn-lg mt-5 mx-2" @click.prevent="submit">提 交</button>
            </div>
            
        </form>
    </div>
    <Hint v-if="isOk" :msg="msg" @btnok="isOk=false"></Hint>
</template>

<script>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { useRouter, useRoute } from 'vue-router'
import { JOBS_TYPE, JOBS_SCHOOLING, SALARYINDEX, ADVANTAGE, AGEINDEX } from '@/lib/data'
export default {
    name:'jobsEdit', //编辑招聘信息
    components:{
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const ready = ref(false)
        const address = ref()
        const msg = { type: 'success', message: '提交成功', url: ''}
        const isOk = ref(false)
         const companyData = ref([
            {"id":100,"name":"一二一通信"},
            {"id":101,"name":"97打工网"}
        ])

        let data = ref({
            id: null,
            company_id: null,
            name: "",
            type_id: 0,//岗位类型 技工、普工--->jobs_type
            content: "",
            salarys: 0,//综合工资
            tags: [],//包吃包住、不穿无尘衣
            schooling: 0,
            ages: 0,
            address: [],
            interview_time: "",
            people_number: 0,
            contact: "",
            contact_tel: null,
        })

//获取数据
    function getData(id) {
      Axios.get('http://localhost:5000/jobs/' + id).then((response) => {
        if(response.data.code == 0) {
          data.value = response.data.data
        }
      })
    }
//获取企业数据
    function getcompany(){
        Axios.get('http://localhost:5000/companys').then((response) => {
            if(response.data.code == 0){
                // companyData.value =response.data.data
            }
        })
    }
        onMounted(() => {
            console.log(route.params.id)
            getData(route.params.id)
            getcompany()
            data.value = 
                {
                    id: 100,
                    company_id: 101,
                    name: "人事主管",
                    type_id: 0,//岗位类型 技工、普工--->jobs_type
                    content: "这是一个测试数据。。。。。。。。",
                    salarys: 1,//综合工资
                    tags: [0,2,3,5],//包吃包住、不穿无尘衣
                    schooling: 2,
                    ages: "2",
                    address: {"province": '310000', 'city': '310100', 'area': '310104'},
                    interview_time: "周-至周5,下午2-3点",
                    people_number:1000,
                    homeshow: 1,
                    home_price: 20,
                    contact: "simon",
                    contact_tel: 180180180888,
                }
            address.value = data.value.address
            ready.value = true
        })

        function submit() {
            Axios.put('http://localhost:5000/jobs/' + route.params.id).then((response) => {
                if (response.data.code == 0){
                    msg.type = 'success'
                    msg.message = '提交成功'
                    msg.url = '/jobs/'
                    isOk.value = true
                    return
                } else {
                    msg.type = 'danger'
                    msg.message = response.data.message
                    isOk.value = true
                    return
                }
            })
        }

        function saveAddr(val) {
            data.value.address = val
        }
        function cancel() {
            router.push('/jobs')
            // window.location.href = '/jobs'
        }
        return {
            submit,
            msg,
            isOk,
            cancel,
            ready,
            JOBS_SCHOOLING,
            data,
            SALARYINDEX,
            companyData,
            JOBS_TYPE,
            ADVANTAGE,
            AGEINDEX,
            saveAddr,
            address
        }
    }
}
</script>

<style scoped>
.rqd {
    color: red;
}
</style>